<template>
  <a-modal
    id="analytics-page-video-modal"
    v-model:visible="showVideoModal"
    centered
    :title="videoToPlay?.fileName"
    destroy-on-close
    width="100%"
    wrap-class-name="full-modal"
    :body-style="{ padding: '10px 12px' }"
    :footer="null"
    @cancel="handleCloseVideoModal"
  >
    <recorded-inference
      :key="videoToPlay"
      :is-fetching-video-url="isFetchingVideoUrl"
      :video="videoToPlay"
      :steps-list="stepsList"
      :comments="videoToPlay?.comments"
      @updateVideo="updateVideoRecord"
    />
  </a-modal>

  <a-row :gutter="[16, 16]">
    <a-col span="24">
      <Filters
        ref="filtersComponent"
        :is-no-data-available="isNoDataAvailable"
        :is-analytics="true"
        @change="handleGetData"
      />
    </a-col>
    <a-col span="24" class="text-right">
      <a-space>
        <a-button
          v-if="
            !isNoDataAvailable &&
            !showCompareAnalytics &&
            role == roles.support_user
          "
          type="primary"
          :disabled="isFetchingCycles"
          @click="downloadSheet"
        >
          Export Process Sheet
          <template #icon>
            <export-outlined />
          </template>
        </a-button>

        <a-button
          v-if="!isNoDataAvailable && !showCompareAnalytics"
          type="primary"
          :disabled="isFetchingCycles"
          @click="exportCyclesCSV"
        >
          Export cycles CSV
          <template #icon>
            <export-outlined />
          </template>
        </a-button>

        <a-button
          v-if="!isNoDataAvailable && !showCompareAnalytics"
          type="primary"
          class="ml-auto"
          :disabled="isFetchingCycles"
          @click="$refs.filtersComponent.redirectToPage('Trace')"
        >
          Trace
          <template #icon>
            <i class="bi bi-box-arrow-up-right mr-2" />
          </template>
        </a-button>
      </a-space>
    </a-col>
    <a-col span="24">
      <a-divider orientation="left" class="mt-2"> Dashboard </a-divider>

      <div class="d-flex">
        <span
          v-if="isDataNotFound && !isFetchingCycles"
          class="text-center mb-3"
          :class="{
            'w-50': showCompareAnalytics,
            'w-100': !showCompareAnalytics,
          }"
        >
          <a-typography :level="4"> No Data Available </a-typography>
        </span>
        <span
          v-if="
            isDataForComparisonNotFound && !isFetchingAnalyticsForComparison
          "
          class="text-center mb-3 w-50 ml-auto"
        >
          <a-typography :level="4"> No Data Available </a-typography>
        </span>
      </div>
    </a-col>

    <!-- Cards -->
    <a-col
      v-for="(card, index) in cards"
      :key="index"
      :xs="card.colXS"
      :sm="card.colSM"
      :md="card.colMD"
      :lg="card.colLG"
      :xl="card.colXL"
    >
      <CardItem :card="card" :card-index="index" />
    </a-col>

    <!-- Charts -->
    <a-col
      v-for="(chart, index) in charts"
      :key="index"
      :xs="chart.colXS"
      :sm="chart.colSM"
      :md="chart.colMD"
      :lg="chart.colLG"
      :xl="chart.colXL"
    >
      <ChartItem
        v-if="chart.visible"
        :options="chart"
        :total-cycles="
          cards.find((card) => card.key == 'number_of_cycles').value
        "
        :chart-index="index"
      />
    </a-col>
  </a-row>
</template>

<script>
import { ExportOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import { evaluateExpression } from 'src/components/shared/Helpers/expressionHelper';
import parseTaskList from 'src/components/shared/Helpers/parseTaskList';
import {
  cardIndicesForFirstDateRange,
  cardIndicesForFirstDateRangeWithComparison,
  cardIndicesForSecondDateRange,
  cards,
  cardsKeys,
  charts,
  chartsIndicesForFirstDateRange,
  chartsIndicesForFirstDateRangeWithComparison,
  chartsIndicesForSecondDateRange,
  screenSizes,
} from 'src/config/charts-config.js';
import { dateTimeFormat } from 'src/config/date-format-config';
import { roles } from 'src/config/roles-config.js';
import traceMixin from 'src/mixins/analyticsTrace';
import spaceMixin from 'src/mixins/handleSpace';
import CycleService from 'src/services/cycles.js';
import TaskRecordService from 'src/services/taskRecord';
import TelemetryService from 'src/services/telemetry';
import VideoService from 'src/services/videos.js';
import { getSortedDevice } from 'src/utils/device';
import {
  deepClone,
  getSortedTask,
  getTaskNameAndProcess,
} from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import {
  createCustomerInfoTable,
  createCustomerNoteTable,
  createCustomerProcessTable,
  createGeneralNotesTable,
  createModelPerformanceTable,
  createPeriodicAncillary,
  createProcessDescriptionTable,
} from '../../../../utils/customerProcessTables';
// import Filters from '../../../shared/Components/AnalyticsTraceFilters.vue';
import Filters from '../../../shared/Components/AdvanceFilters';

import CardItem from '../../../shared/Components/CardItem.vue';
import RecordedInference from '../../../shared/Components/RecordedInference.vue';
import jsonToCsvDownload from '../../../shared/Helpers/jsonToCsvDownload';
import ChartItem from './Charts/ChartItem.vue';
export default {
  components: {
    RecordedInference,
    ChartItem,
    CardItem,
    Filters,
    ExportOutlined,
  },
  mixins: [traceMixin, spaceMixin],
  inject: ['toast'],
  provide() {
    return {
      redirectToTrace: this.handleRedirectWithCycleDuration,
      redirectMissedToTrace: this.handleRedirectWithMissedSteps,
      playCycle: this.startPlayingCycle,
    };
  },
  setup() {
    return { roles };
  },

  data() {
    return {
      chartList: deepClone(charts),
      cards: deepClone(cards),
      analytics_data: {},
      analyticsDataForComparison: {},
      isDataNotFound: false,
      isDataForComparisonNotFound: false,
      filterValues: null,
      filterValuesForComparison: null,
      videoList: [],
      cycle_type: 'all_cycles',
      pagination: {
        pageSize: 6,
        currentPage: 1,
        options: [6, 10, 20, 50],
      },
      filteredVideoList: [],
      cycleWiseMissedSteps: null,
      jsonToCsvDownload,
      showVideoModal: false,
      videoToPlay: null,
      isFetchingCycleDetails: false,
      isFetchingVideoUrl: false,
      videosToPlay: {},
      stepsList: [],
      initialRow: 14,
      dateHelper,
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'devices',
      'stepAvgTime',
      'indexToStepsMapping',
      'deviceSerialNumToDisplayNameMap',
      'stepTime',
      'avgCycleTime',
      'isFetchingCycles',
      'selectedTask',
      'taskDetails',
      'taskName',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison',
      'dateRangeForComparison',
      'allTasks',
      'deviceDisplayNameToSerialNumMap',
      'role',
    ]),

    charts() {
      const hide = [];
      return this.chartList.filter((chart) => !hide.includes(chart.title));
    },

    isNoDataAvailable() {
      return Object.keys(this.analytics_data).length === 0;
    },

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    deviceOptions() {
      // const filteredDevices = this.devices?.filter(
      //   device =>
      //     !this.selectedDevices.includes(
      //       device.display_name || device.Serial_number
      //     ) && !!device.Serial_number
      // );
      return getSortedDevice(this.devices);
    },
  },
  watch: {
    taskDetails(value) {
      this.stepsList = parseTaskList(value);
    },

    showCompareAnalytics(value) {
      if (value) this.handleCompareWithDifferentTimeRange();
      else this.closeCompareWithDifferentTimeRange();
    },
  },
  mounted() {
    this.stepsList = parseTaskList(this.taskDetails);
  },
  methods: {
    ...mapActions([
      'setIsFetchingCycles',
      'setShowCompareAnalytics',
      'setIsFetchingAnalyticsForComparison',
    ]),

    determinePercentage(value) {
      let totalCycles = this.cards.find(
        (card) => card.key == 'number_of_cycles'
      ).value;
      let accuracy = totalCycles - value;
      return Math.floor(((accuracy / totalCycles) * 100).toFixed(2)) + '%';
    },

    downloadSheet() {
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1', {
        properties: { tabColor: { argb: 'FF00FF00' } },
      });

      let { processes } = getTaskNameAndProcess(this.taskDetails);

      let chartData = this.charts.find(
        (data) => data.title == 'Missed Steps'
      ).data;

      let accuracyPercent = chartData.map((data) =>
        this.determinePercentage(data)
      );

      let subSteps = [];
      processes[0].steps.forEach((data) => {
        subSteps.push(...data.substeps);
      });

      let numberOfCycles = this.cards.find(
        (card) => card.key == 'number_of_cycles'
      ).value;

      let dateOfReview = `${this.filterValues.datetime_start.split(' ')[0]} - ${
        this.filterValues.datetime_end.split(' ')[0]
      }`;

      // customer info Table
      // start
      createCustomerInfoTable(
        worksheet,
        this.organization,
        JSON.parse(this.filterValues.devices).join(', ')
      );
      // end
      // Process Description
      // start
      createProcessDescriptionTable(worksheet);
      // end
      // Customer Notes Table
      createCustomerNoteTable(worksheet);
      // end
      // start main table
      createCustomerProcessTable(
        worksheet,
        processes,
        accuracyPercent,
        numberOfCycles,
        dateOfReview,
        this.initialRow
      );
      // end main table
      // Define headers and their widths
      // model performance table
      createModelPerformanceTable(worksheet);
      // model Periodic Ancillary Activities table
      let startingRow = this.initialRow + subSteps.length + 1;
      createPeriodicAncillary(worksheet, startingRow);
      // end
      // General Notes table
      // start
      createGeneralNotesTable(worksheet, startingRow);
      // end
      // reset initial raw
      this.initialRow = 14;
      // Write the workbook to a buffer and save as an Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        this.saveAsExcel(buffer, 'Stats.xlsx');
      });
    },

    saveAsExcel(buffer, fileName) {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },

    getDateRange(dateRange) {
      const firstDate = `${dayjs(dateRange[0]).format(dateTimeFormat)}`;
      const secondDate = `${`${dayjs(dateRange[1]).format(dateTimeFormat)}`}`;
      return `${firstDate} → ${secondDate}`;
    },

    handleCompareWithDifferentTimeRange() {
      this.cards = this.cards.flatMap((card, index) => [card, cards[index]]);
      this.chartList = this.chartList.flatMap((chart, index) => {
        const newChart = deepClone(charts[index]);
        screenSizes.forEach((screenSize) => {
          chart[screenSize] = newChart[screenSize] = 12;
        });
        return [chart, newChart];
      });
    },

    closeCompareWithDifferentTimeRange() {
      this.isDataForComparisonNotFound = false;

      this.cards = deepClone(cards);
      this.chartList = deepClone(charts);
      this.updateCards();
      this.updateCharts();
    },

    handleRedirectWithCycleDuration({
      bin,
      binSize,
      isFilteredOutliers,
      chartIndex,
    }) {
      const { minCycleTime, maxCycleTime } = this.getMinMaxCycleTime(
        bin,
        binSize,
        isFilteredOutliers,
        chartIndex
      );
      const query = {
        start_time: Math.floor(minCycleTime),
        end_time: Math.ceil(maxCycleTime),
      };
      this.$refs.filtersComponent.redirectToPage('Trace', query, chartIndex);
    },

    handleRedirectWithMissedSteps({ missedSteps, chartIndex }) {
      const query = {
        missed_steps: JSON.stringify(missedSteps),
      };
      this.$refs.filtersComponent.redirectToPage('Trace', query, chartIndex);
    },

    async handleGetData(filters) {
      if (filters.isComparing) this.getAnalyticsForComparison(filters);
      else this.getAnalytics(filters);
    },

    async getAnalytics(filters) {
      this.setIsFetchingCycles(true);
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(
        false,
        filters
      );

      this.filterValues = filters;
      this.setIsFetchingCycles(false);
      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCharts();
        this.clearCards();
        this.analytics_data = {};
        this.isDataNotFound = true;
        return;
      }
      this.isDataNotFound = false;

      this.analytics_data = data;
      this.updateCards();
      this.updateCharts();
    },

    async getAnalyticsForComparison(filters) {
      this.setIsFetchingAnalyticsForComparison(true);
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(
        false,
        filters
      );

      this.filterValuesForComparison = filters;

      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCards(true);
        this.clearCharts(false);
        this.analyticsDataForComparison = {};
        this.isDataForComparisonNotFound = true;
        this.setIsFetchingAnalyticsForComparison(false);
        return;
      }
      this.isDataForComparisonNotFound = false;

      this.analyticsDataForComparison = data;
      this.updateCards(true);
      this.updateCharts(true);
      this.setIsFetchingAnalyticsForComparison(false);
    },

    fetchCycleWiseMissedSteps() {
      if (this.cycleWiseMissedSteps) return;
      return new Promise(async (resolve) => {
        const queryParams = {
          organization: this.organization,
          devices: this.filterValues.devices,
          task: this.filterValues.task,
          datetime_start: this.filterValues.datetime_start,
          datetime_end: this.filterValues.datetime_end,
        };
        const [error, data] = await CycleService.fetchCycleWiseMissedStepsV2(
          true,
          queryParams
        );
        if (error) {
          console.log({ error });
          this.cycleWiseMissedSteps = null;
          resolve();
        }
        this.cycleWiseMissedSteps = data;
        resolve();
      });
    },

    async exportCyclesCSV() {
      if (this.analytics_data) {
        await this.fetchCycleWiseMissedSteps();
        const dataToExport = this?.cycleWiseMissedSteps?.map((cycle) => ({
          ...cycle,
          'Device Name': this.deviceSerialNumToDisplayNameMap[cycle['Device']],
          'Missed Steps': this.getMissedSteps(cycle['Missed Steps']),
        }));
        const { task, datetime_start, datetime_end } = this.filterValues;
        const start_date = datetime_start.split(' ')[0];
        const end_date = datetime_end.split(' ')[0];
        jsonToCsvDownload(dataToExport, `${task}_${start_date}_${end_date}`);
      } else this.toast.info('No Data Found!');
    },

    getMissedSteps(missedStepsIndex) {
      if (!missedStepsIndex) return '';
      return missedStepsIndex
        .split(', ')
        .map((stepIndex) => this.indexToStepsMapping[stepIndex])
        .join('; ')
        .replaceAll(',', '&');
    },

    getMinMaxCycleTime(bin, binSize, isFilteredOutliers, chartIndex) {
      let key = 'analytics_data';
      if (this.showCompareAnalytics) {
        const isChartIndexInSecondDateRange =
          chartsIndicesForSecondDateRange.includes(chartIndex);
        key = isChartIndexInSecondDateRange
          ? 'analyticsDataForComparison'
          : key;
      }

      const maxCycleTime = this[key]['cycle_time_dist']['max_cycle_time'];

      const { bins_of_cycle_times, bins_of_cycle_times_without_outliers } =
        this[key]['cycle_time_dist']['data'][binSize];

      const cyclesTimes = isFilteredOutliers
        ? bins_of_cycle_times_without_outliers
        : bins_of_cycle_times;

      const maxPlotBin = Object.keys(cyclesTimes).at(-1);

      const factor = binSize / 2;
      bin = Number(bin);

      const obj = {
        minCycleTime: bin - factor,
        maxCycleTime: maxPlotBin == bin ? maxCycleTime : bin + factor,
      };
      return obj;
    },

    updateCards(isComparing = false) {
      const cards = [...this.cards];
      const analyticsDataKey = isComparing
        ? 'analyticsDataForComparison'
        : 'analytics_data';

      let cardsIndices = [];

      if (isComparing) {
        cardsIndices = cardIndicesForSecondDateRange;
      } else {
        cardsIndices = this.showCompareAnalytics
          ? cardIndicesForFirstDateRangeWithComparison
          : cardIndicesForFirstDateRange;
      }
      this.updateCardsAccToIndices(cardsIndices, cards, this[analyticsDataKey]);

      this.cards = cards;
    },

    updateCardsAccToIndices(cardsIndices, cards, data) {
      const { cycles_with_errors, cycles_without_errors } = data.cycle_stats;
      cardsKeys.forEach((cardKey, index) => {
        cards[cardsIndices[index]] = {
          ...cards[cardsIndices[index]],
          value: data.cycle_stats[cardKey],
        };
        if (index === 0) {
          cards[cardsIndices[index]] = {
            ...cards[cardsIndices[index]],
            goodCycles: cycles_without_errors,
            badCycles: cycles_with_errors,
          };
        }
      });
    },

    clearCards(isComparing = false) {
      const cards = [...this.cards];
      let cardsIndices = [];
      if (isComparing) {
        cardsIndices = cardIndicesForSecondDateRange;
      } else {
        cardsIndices = this.showCompareAnalytics
          ? cardIndicesForFirstDateRangeWithComparison
          : cardIndicesForFirstDateRange;
      }
      cardsKeys.forEach((_, index) => {
        cards[cardsIndices[index]] = {
          ...cards[cardsIndices[index]],
          value: 0,
        };
        if (index === 0) {
          cards[cardsIndices[index]] = {
            ...cards[cardsIndices[index]],
            goodCycles: 0,
            badCycles: 0,
          };
        }
      });
      this.cards = cards;
    },

    async updateCharts(isComparing = false) {
      const charts = [...this.chartList];
      const chartsHandlers = [
        this.updateStepAvgTimeChart,
        this.updateCycleTimeDistributionChart,
        this.updateMissedStepsChart,
        this.updateNonRelatedActivityChart,
        this.updateTimeDistributionChart,
        this.updateSessionGraph,
        this.updateTimeLineGraphData,
      ];
      const analyticsDataKey = isComparing
        ? 'analyticsDataForComparison'
        : 'analytics_data';
      let chartsIndices = [];
      if (isComparing) {
        chartsIndices = chartsIndicesForSecondDateRange;
      } else {
        chartsIndices = this.showCompareAnalytics
          ? chartsIndicesForFirstDateRangeWithComparison
          : chartsIndicesForFirstDateRange;
      }
      chartsHandlers.forEach((chartHandler, index) => {
        chartHandler(chartsIndices[index], charts, this[analyticsDataKey]);
      });

      this.chartList = charts;
    },

    clearCharts() {
      const chartList = [...this.chartList];
      for (let chart in chartList) {
        chartList[chart].data = '';
        chartList[chart].labels = '';
        chartList[chart].stepsAvgTime = '';
        chartList[chart].stepsStdDev = '';
      }
      this.chartList = chartList;
    },

    updateStepAvgTimeChart(chartIndex, charts, analyticsData) {
      if (!analyticsData['steps_avg_time']) return;
      const { steps_avg_time } = analyticsData;
      const { data, labels, stepsAvgTime, stepsStdDev } = steps_avg_time;
      charts[chartIndex]['data'] = data;
      charts[chartIndex]['labels'] = labels;
      charts[chartIndex]['stepsAvgTime'] = stepsAvgTime;
      charts[chartIndex]['stepsStdDev'] = stepsStdDev;
    },

    updateCycleTimeDistributionChart(chartIndex, charts, analyticsData) {
      if (!analyticsData['cycle_time_dist']) return;
      const { cycle_time_dist } = analyticsData;
      const { data, plotline } = cycle_time_dist;
      charts[chartIndex]['plotLine'] = plotline;
      charts[chartIndex]['data'] = Object.entries(data).reduce(
        (res, [key, value]) => {
          const { bins_of_cycle_times, bins_of_cycle_times_without_outliers } =
            value;
          res[key] = {
            bins_of_cycle_times: this.getFreqAndBins(bins_of_cycle_times),
            bins_of_cycle_times_without_outliers: this.getFreqAndBins(
              bins_of_cycle_times_without_outliers
            ),
          };
          return res;
        },
        {}
      );
    },

    getFreqAndBins(binsOfCycleTimes) {
      const freq = [];
      const bins = [];
      Object.entries(binsOfCycleTimes).forEach(([bin, cyclesCount]) => {
        bins.push(bin);
        freq.push(cyclesCount);
      });
      return {
        freq,
        bins,
      };
    },

    updateMissedStepsChart(chartIndex, charts, analyticsData) {
      if (!analyticsData['missed_steps']) return;
      const { data, labels } = analyticsData.missed_steps;
      charts[chartIndex]['data'] = data;
      charts[chartIndex]['labels'] = labels;
    },

    updateNonRelatedActivityChart(chartIndex, charts, analyticsData) {
      if (!analyticsData['nva_time_session']) return;
      const { data, labels } = analyticsData.nva_time_session;
      charts[chartIndex]['data'] = data;
      charts[chartIndex]['labels'] = labels;
    },

    updateTimeDistributionChart(chartIndex, charts, analyticsData) {
      if (!analyticsData['line_chart']) return;
      const { data } = analyticsData.line_chart;
      const labels = [],
        _data = [];

      Object.entries(data).forEach(([key, value]) => {
        labels.push(key);
        _data.push({
          y: value.cycle_time,
          color: value.is_traced_cycle ? '#2caffe' : 'lightgray',
          custom: {
            isTraced: value.is_traced_cycle,
          },
        });
      });

      charts[chartIndex]['data'] = _data;
      charts[chartIndex]['labels'] = labels;
    },

    updateSessionGraph(chartIndex, charts, analyticsData) {
      if (!analyticsData['cycle_count_by_session']) return;
      const { data, labels } = analyticsData.cycle_count_by_session;
      charts[chartIndex]['data'] = data;
      charts[chartIndex]['labels'] = labels;
    },

    getSessionChartData(temp, labels) {
      temp = deepClone(temp);
      let maxSessionCount = Math.max(
        ...Object.values(temp).map((r) => r.length)
      );
      const chartData = [];
      for (let i = 0; i < maxSessionCount; i++) {
        let verticalBar = {
          type: 'errorbar',
          data: [],
        };
        chartData.push({
          type: 'bar',
          data: labels.map((d) => {
            const y = temp[d]?.shift();
            if (!y) {
              verticalBar.data.push([]);
              return null;
            }
            const { bar, line } = this.getSessionChartPointData(y);
            verticalBar.data.push(line);
            return bar;
          }),
        });
        chartData.push(verticalBar);
      }
      return chartData;
    },

    getSessionChartPointData(y) {
      let totalCycles = null;
      if (this.avgCycleTime) {
        totalCycles = Math.round(y['sessionDur'] / this.avgCycleTime);
      }
      return {
        bar: {
          startTime: y['startTime'],
          endTime: y['endTime'],
          y: y['cycleCount'],
          totalCycles: totalCycles,
          sessionDur: y['sessionDur'],
          color: '#8AC3F6',
        },
        line: totalCycles ? [0, totalCycles] : [],
      };
    },

    getSessionNonRelatedActivityData(temp, labels) {
      temp = deepClone(temp);
      let maxSessionCount = Math.max(
        ...Object.values(temp).map((r) => r.length)
      );
      const chartData = [];
      for (let i = 0; i < maxSessionCount; i++) {
        chartData.push({
          type: 'bar',
          data: labels.map((d) => {
            const y = temp[d]?.shift();
            // const y = temp[d][i];
            if (!y) return null;

            const bar = this.getSessionNonRelatedActivityValue(y);

            return bar;
          }),
        });
      }
      return chartData;
    },

    getSessionNonRelatedActivityValue(y) {
      return {
        startTime: y['startTime'],
        endTime: y['endTime'],
        y: y['avg_background_time'],
        sessionDur: y['sessionDur'],
        color: '#8AC3F6',
      };
    },

    updateTimeLineGraphData(chartIndex, charts, analyticsData) {
      if (!analyticsData['time_line_chart']) return;
      const { data, chart_height, dates } = analyticsData.time_line_chart;
      charts[chartIndex].data = data.map((device) => ({
        ...device,
        name: this.deviceSerialNumToDisplayNameMap[device.name],
      }));
      charts[chartIndex].chartHeight = chart_height;
      charts[chartIndex].dates = dates;
    },

    async startPlayingCycle(cycleIdentifier) {
      this.showVideoModal = true;
      this.isFetchingCycleDetails = true;
      if (this.isVideoExistsInState(cycleIdentifier)) return;
      this.isFetchingVideoUrl = true;

      const [, data] = await this.getCycleDetails(cycleIdentifier);

      if (data.fileName) {
        const [video_url, preds_url] = await Promise.all([
          this.getVideoUrl(data),
          this.getPredictionFileUrl(data),
        ]);

        if (!!data.entity_id && !!data.no_of_comments) {
          const comment_response = await VideoService.getCommentsForEntity(
            data.entity_id
          );
          data['comments'] = comment_response;
        } else {
          data['comments'];
        }

        const videoFPS = await this.getVideoFPS(data);

        this.isFetchingCycleDetails = false;
        this.isFetchingVideoUrl = false;

        if ([video_url, preds_url].includes('error')) {
          this.toast.error('Error occure while fetching url!');
          return;
        }
        this.setVideoToPlay({ ...data, fps: videoFPS }, video_url, preds_url);
      } else {
        this.setVideoToPlay(data);
        this.isFetchingCycleDetails = false;
        this.isFetchingVideoUrl = false;
      }
    },

    isVideoExistsInState(cycleIdentifier) {
      if (this.videosToPlay[cycleIdentifier]) {
        this.videoToPlay = this.videosToPlay[cycleIdentifier];
        this.isFetchingCycleDetails = false;
        return true;
      }
      return false;
    },

    getCycleDetails(cycleIdentifier) {
      const { device_id } =
        this.analytics_data.line_chart.data[cycleIdentifier];
      return new Promise(async (resolve) => {
        const [error, data] = await CycleService.fetchCycleDetailsV2(
          cycleIdentifier,
          device_id,
          this.selectedTask
        );

        data['missed_steps'] = data?.missed_steps?.join(',') ?? '';
        resolve([error, data]);
      });
    },

    async getVideoUrl(cycleDetails) {
      if (!cycleDetails?.id) return new Error('Video does not exist!');
      const { fileName, device_id } = cycleDetails;
      const payload = {
        bucket_name: `${this.organization}-videos`,
        object_path: `${device_id}/${this.selectedTask}/Processed/${fileName}`,
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      return error || data?.presigned_url;
    },

    async getPredictionFileUrl(video) {
      const { device_id } = video;
      const fileName = video.fileName + '_preds.json';
      let filePath = `${device_id}/${this.selectedTask}/Processed/${fileName}`;
      const payload = {
        bucket_name: `${this.organization}-videos`,
        object_path: filePath,
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      return error || data?.presigned_url;
    },

    getVideoFPS(video) {
      return new Promise(async (resolve) => {
        let payload = { id: video.id };
        const [error, res] = await TaskRecordService.getVideoMetaData(
          payload,
          false
        );
        if (error) {
          console.log({ error });
          return resolve(30);
        }
        const { data } = res;
        let { fps } = data;
        return fps;
      });
    },

    setVideoToPlay(videoDetails, fileURL, preds_url) {
      if (!videoDetails) return;
      const { cycle_identifier } = videoDetails;
      this.videoToPlay = this.appendMissedSteps({
        ...videoDetails,
        fileURL,
        per_frame_prediction_file_url: preds_url,
      });

      this.videoToPlay = {
        ...this.videoToPlay,
        isVideoExists: !!fileURL,
        fileName: videoDetails.fileName || videoDetails.cycle_identifier,
      };

      this.videosToPlay[cycle_identifier] = { ...this.videoToPlay };
    },

    updateVideoRecord({ videoName, updatedObj }) {
      const cycleIdentifier = videoName?.split('.')[0];
      if (!this.videosToPlay[cycleIdentifier]) return;

      this.videosToPlay[cycleIdentifier] = this.videosToPlay = {
        ...this.videosToPlay,
        [cycleIdentifier]: {
          ...this.videosToPlay[cycleIdentifier],
          ...updatedObj,
        },
      };
    },

    handleCloseVideoModal() {
      this.showVideoModal = false;
      this.videoToPlay = null;
    },
  },
};
</script>
